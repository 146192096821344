.loginPage {
    padding: 0 13% 0 13%;
}

a {
    text-decoration: underline;
    color: var(--primary-sky-blue);
}

.bLogin {
    color: var(--primary-sky-blue);
}

h2 {
    padding-bottom: 5px;;
}

.textRow {
    display: flex;
    flex-direction: row;
    background-color: none;
}

.textSection {
    width: 50%;
    padding-right: 5%;
}

.formSection {
    width: 45%;
    padding-top: 3%;
    padding-bottom: 20%;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 5% 8%;
    background-color: #C68734;
    color: white;
}

input {
    width: 98%;
    font-size: 18px;
    padding: 1% 0%;
    margin-bottom: 3%;
    background-color: #EFEFEF;
    border: none;
}

input[type=submit] {
    width: 50%;
    background-color: #3492C6;
    padding: 2% 0%;
    color: white;
    border-radius: 10px;
}

.swapSection {
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.swapText {
    margin: 0%;
}

.swapFormButton {
    width: 50%;
    font-size: 16px;
    padding: 2% 0%;
    border-radius: 10px;
    background-color: #EFEFEF;
    color: black;
    border: none;
}

.submitButton {
    padding-top: 5%;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .textRow {
        flex-direction: column;
    }
    .textSection, .formSection {
        width: 100%; 
    }
    .form {
        width: 80%;
    }
}