.historyGraphWrapper {
    margin: 0 3% 0 0;
}

.historyButtonArea {
    width: 100%;
    align-items: center;
}

.modGraphButton {
    border-radius: 8px;
}