@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
:root {
  --primary-copper: #C68734;
  --primary-sky-blue: #3492C6;

  --secondary-light-green: #3EC7AE;
  --secondary-coffee: #7A4E14;

  --tertiary-toast: #605940;
  --tertiary-tan: #FFB95C;
  --tertiary-navy: #08517A;
  --tertiary-mallard: #2C7A6C;
  --tertiary-sunflower: #FACA28;
  --tertiary-burnt-red: #9E2C00;
  --tertiary-pink: #E02460;
  --tertiary-sunkiss: #C7552A;

  --light-gray: #EFEFEF;
}


/*                         */
/* GENERAL REUSABLE STYLES */
/*                         */

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-horizontal-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


/*                    */
/* GENERAL PAGE STYLE */
/*                    */

html {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
}

body {
  margin: 0px;
  font-family: 'Open Sans', 'Lato', serif;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  /*
  text-align: center;
  justify-content: center;
  */
  /*background: var(--primary-copper);*/
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  background: white;
  height: 100%;
  width: 100%;
}

h1,
h2 {
  font-family: 'Lato', serif;
  line-height: 1.5em;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 28px;
}

#mainContent {
  margin-left: 0%;
  width: 90%;
}

button {
  margin-top: 5%;
  background-color: #3492C6;
  background-color: var(--primary-sky-blue);
  border-radius: 8px;
  color: white;
  padding: 2% 15%;
  cursor: pointer;
  font-family: 'Lato', serif;
  font-size: 16pt;
}

button:hover {
  border-color: white;
}

select {
  height: 36px;
  font-size: auto;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  margin-bottom: 18px;
}

/*        */
/* HEADER */
/*        */

.header {
  height: 12vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2vh 2vw 0vh 2vw;
}

#logo {
  max-height: 80%;
}

.profileDropdown {
  height: 12vh;
  position: relative;
  display: inline-block;
}

#profile {
  height: 45%;
  padding-top: 2vh;
  cursor: pointer;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.dropdownLabel,
.userName {
  display: block;
  margin: 0;
  font-size: 18px;
  width: 125px;
  height: 30px;
  padding: 10px 20px 7px 20px;
}

.dropdownLabel {
  cursor: pointer;
}

.dropdownLabel a {
  text-decoration: none;
  color: black;
}

.dropdownLabel:hover {
  background-color: #C68734;
  background-color: var(--primary-copper);
}

.profileDropdown:hover .dropdownContent {
  display: block;
}


/*            */
/*    TEMP    */
/*            */

.examBox {
  /*padding-top: 15%;
  padding-bottom: 15%;
  padding-left: 2vw;*/
  max-width: 100%;
  text-align: center;
  justify-content: center;
}


/*        */
/* FOOTER */
/*        */

.footer {
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0px;
  background-color: #EFEFEF;
  background-color: var(--light-gray);
  text-align: center;
  position: fixed;
}

.footerFixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #EFEFEF;
  background-color: var(--light-gray);
  text-align: center;
}

footer p {
  padding: none;
  margin: 0;
  font-size: 12px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.examHeader {
    text-align: center;
    line-height: 2;
    padding: 20px 0px;
    background-color: #efefef;
    width: 100%;
}

.examArea {
    margin: 30px 30px 100px 30px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
}

.examWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#setupInstructions {
    margin-bottom: 10px;
}
.infoSection {
    text-align: left;
    line-height: 2;
    padding: 1px 25% 8px 5%;
    background-color: #efefef;
}

b {
    color: var(--primary-copper);
}

.cardHeader {
    color: var(--primary-copper);
}

.cardArea {
    width: 96%;
    padding-left: 2vw;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.5em;
}

.card {
    /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    width: 46%;
    height: 600px;
    display: inline-block;
    text-align: center;
    margin: 10px;
    border-radius: 10%;
    background-color: white;
}

.card p {
    padding: 0px 20px;
}

.takeTestButton {
    margin-top: 4%;
    background-color: var(--primary-sky-blue);
    border-radius: 8px;
    color: white;
    padding: 2% 15%;
    font-size: 14pt;
}

.examCal {
    width: 80%;
    padding: 0% 10%;
}


@media screen and (max-width: 840px) {
    .cardArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .card {
        width: 90%;
    }
}

.react-calendar {
  width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

/* 
GRAYS OUT DISABLED PARTS OF THE CALENDAR

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

NO NEXT/PREV BUTTONS SO NO NEED FOR THIS
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
*/

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

/*  
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #2a2a2a;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}


.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
*/

.react-calendar__tile {
  max-width: 100%;
  padding: 13px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  color: #2a2a2a;
}

/*
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: var(--primary-sky-blue);
}
*/

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: var(--primary-sky-blue);
}

.react-calendar__tile--active {
  background: var(--primary-sky-blue);
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--primary-sky-blue);
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.historyGraphWrapper {
    margin: 0 3% 0 0;
}

.historyButtonArea {
    width: 100%;
    align-items: center;
}

.modGraphButton {
    border-radius: 8px;
}
.loginPage {
    padding: 0 13% 0 13%;
}

a {
    text-decoration: underline;
    color: var(--primary-sky-blue);
}

.bLogin {
    color: var(--primary-sky-blue);
}

h2 {
    padding-bottom: 5px;;
}

.textRow {
    display: flex;
    flex-direction: row;
    background-color: none;
}

.textSection {
    width: 50%;
    padding-right: 5%;
}

.formSection {
    width: 45%;
    padding-top: 3%;
    padding-bottom: 20%;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 5% 8%;
    background-color: #C68734;
    color: white;
}

input {
    width: 98%;
    font-size: 18px;
    padding: 1% 0%;
    margin-bottom: 3%;
    background-color: #EFEFEF;
    border: none;
}

input[type=submit] {
    width: 50%;
    background-color: #3492C6;
    padding: 2% 0%;
    color: white;
    border-radius: 10px;
}

.swapSection {
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.swapText {
    margin: 0%;
}

.swapFormButton {
    width: 50%;
    font-size: 16px;
    padding: 2% 0%;
    border-radius: 10px;
    background-color: #EFEFEF;
    color: black;
    border: none;
}

.submitButton {
    padding-top: 5%;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .textRow {
        flex-direction: column;
    }
    .textSection, .formSection {
        width: 100%; 
    }
    .form {
        width: 80%;
    }
}
