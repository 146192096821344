.examHeader {
    text-align: center;
    line-height: 2;
    padding: 20px 0px;
    background-color: #efefef;
    width: 100%;
}

.examArea {
    margin: 30px 30px 100px 30px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
}

.examWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#setupInstructions {
    margin-bottom: 10px;
}