@import 'https://fonts.googleapis.com/css?family=Lato';
@import 'https://fonts.googleapis.com/css?family=Open+Sans';

:root {
  --primary-copper: #C68734;
  --primary-sky-blue: #3492C6;

  --secondary-light-green: #3EC7AE;
  --secondary-coffee: #7A4E14;

  --tertiary-toast: #605940;
  --tertiary-tan: #FFB95C;
  --tertiary-navy: #08517A;
  --tertiary-mallard: #2C7A6C;
  --tertiary-sunflower: #FACA28;
  --tertiary-burnt-red: #9E2C00;
  --tertiary-pink: #E02460;
  --tertiary-sunkiss: #C7552A;

  --light-gray: #EFEFEF;
}


/*                         */
/* GENERAL REUSABLE STYLES */
/*                         */

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-horizontal-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


/*                    */
/* GENERAL PAGE STYLE */
/*                    */

html {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
}

body {
  margin: 0px;
  font-family: 'Open Sans', 'Lato', serif;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  /*
  text-align: center;
  justify-content: center;
  */
  /*background: var(--primary-copper);*/
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  background: white;
  height: 100%;
  width: 100%;
}

h1,
h2 {
  font-family: 'Lato', serif;
  line-height: 1.5em;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 28px;
}

#mainContent {
  margin-left: 0%;
  width: 90%;
}

button {
  margin-top: 5%;
  background-color: var(--primary-sky-blue);
  border-radius: 8px;
  color: white;
  padding: 2% 15%;
  cursor: pointer;
  font-family: 'Lato', serif;
  font-size: 16pt;
}

button:hover {
  border-color: white;
}

select {
  height: 36px;
  font-size: auto;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  margin-bottom: 18px;
}

/*        */
/* HEADER */
/*        */

.header {
  height: 12vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2vh 2vw 0vh 2vw;
}

#logo {
  max-height: 80%;
}

.profileDropdown {
  height: 12vh;
  position: relative;
  display: inline-block;
}

#profile {
  height: 45%;
  padding-top: 2vh;
  cursor: pointer;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.dropdownLabel,
.userName {
  display: block;
  margin: 0;
  font-size: 18px;
  width: 125px;
  height: 30px;
  padding: 10px 20px 7px 20px;
}

.dropdownLabel {
  cursor: pointer;
}

.dropdownLabel a {
  text-decoration: none;
  color: black;
}

.dropdownLabel:hover {
  background-color: var(--primary-copper);
}

.profileDropdown:hover .dropdownContent {
  display: block;
}


/*            */
/*    TEMP    */
/*            */

.examBox {
  /*padding-top: 15%;
  padding-bottom: 15%;
  padding-left: 2vw;*/
  max-width: 100%;
  text-align: center;
  justify-content: center;
}


/*        */
/* FOOTER */
/*        */

.footer {
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0px;
  background-color: var(--light-gray);
  text-align: center;
  position: fixed;
}

.footerFixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: var(--light-gray);
  text-align: center;
}

footer p {
  padding: none;
  margin: 0;
  font-size: 12px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}