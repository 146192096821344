.infoSection {
    text-align: left;
    line-height: 2;
    padding: 1px 25% 8px 5%;
    background-color: #efefef;
}

b {
    color: var(--primary-copper);
}

.cardHeader {
    color: var(--primary-copper);
}

.cardArea {
    width: 96%;
    padding-left: 2vw;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.5em;
}

.card {
    /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    width: 46%;
    height: 600px;
    display: inline-block;
    text-align: center;
    margin: 10px;
    border-radius: 10%;
    background-color: white;
}

.card p {
    padding: 0px 20px;
}

.takeTestButton {
    margin-top: 4%;
    background-color: var(--primary-sky-blue);
    border-radius: 8px;
    color: white;
    padding: 2% 15%;
    font-size: 14pt;
}

.examCal {
    width: 80%;
    padding: 0% 10%;
}


@media screen and (max-width: 840px) {
    .cardArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .card {
        width: 90%;
    }
}
